import styled from 'styled-components';

const DateTime = styled.time`
  margin-top: 12px;
  font-size: 0.875rem;
  font-weight: var(--font-weight-regular);
  color: var(--color-text-3);
`;

export default DateTime;
