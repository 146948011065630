import styled from 'styled-components';

const Category = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-text-3);
`;

export default Category;
